import { isValidationError } from '../type-guards/isValidationError'
import type { FieldWithValidation } from '~/composables/useFields/types'

export const applyValidationErrors = (
  error: unknown,
  fields?: MaybeRef<Record<string, FieldWithValidation<unknown>>>,
): boolean => {
  if (fields && isValidationError(error)) {
    const handledByField = Object.entries(error.body.errors).reduce<boolean>(
      (result, [key, messages]) => {
        const field = unref(fields)[key]

        if (field) {
          field.data.validationError = messages.map(
            (message) => `apiErrors.${message}`,
          )

          return true
        }

        return result
      },
      false,
    )

    return handledByField
  }

  return false
}

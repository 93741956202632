import { ApiError } from '~/services/sdk/api'

export interface BatchError<T extends PropertyKey> {
  index: number
  errors: {
    [K in T]: string[]
  }
}

export interface BatchErrorBody<T extends PropertyKey> {
  batchErrors: BatchError<T>[]
}

export const isBatchValidationErrors = <T extends PropertyKey>(
  error: unknown,
): error is Omit<ApiError, 'body'> & {
  body: BatchErrorBody<T>
} => {
  return (
    error instanceof ApiError &&
    isTrueObject<BatchErrorBody<T>>(error.body) &&
    Array.isArray(error.body.batchErrors) &&
    !!error.body.batchErrors.length
  )
}

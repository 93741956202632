import type { FieldWithValidation } from '../useFields/types'
import type { RequestCallback } from './types'
import { applyValidationErrors } from './utils/applyValidationErrors'
import { isAuthUnknownError } from './type-guards/isAuthUnknownError'
import { applyBatchValidationErrors } from './utils/applyBatchValidationErrors'
import { ApiError } from '~/services/sdk/api'

export const useRequest = (
  callback: RequestCallback,
  props?: Partial<{
    fields: MaybeRef<Record<string, FieldWithValidation<unknown>>>
    batchFields: MaybeRef<Record<string, FieldWithValidation<unknown>>[]>
    processing: boolean
    showErrorPage: boolean
    onBatchValidationError: (index: number, field: string) => unknown
  }>,
) => {
  const toast = useToast()
  const api = useAPI()

  const processing = ref<boolean>(!!props?.processing)

  const action = async (): Promise<void> => {
    processing.value = true

    try {
      const successfulMessage = await callback(api)

      if (typeof successfulMessage === 'string') {
        toast.success(successfulMessage)
      }
    } catch (error) {
      const isValidationError =
        applyValidationErrors(error, props?.fields) ||
        applyBatchValidationErrors(
          error,
          props?.batchFields,
          props?.onBatchValidationError,
        )

      if (isValidationError) return

      const isApiError = error instanceof ApiError

      if (props?.showErrorPage) {
        showError({
          statusCode: isApiError ? error.status : 500,
        })
      } else if (isAuthUnknownError(error)) {
        toast.danger(error.error_description || error.error)
      } else if (isApiError && error.status === 401) {
        toast.danger('error.401')
      } else if (isApiError && error.body?.detail) {
        toast.danger(`apiErrors.${error.body.detail}`)
      } else if (isApiError && error.body?.title) {
        toast.danger(error.body.title)
      } else if (isApiError && error.message) {
        toast.danger(error.message)
      } else {
        toast.danger('error.500')
      }
    } finally {
      processing.value = false
    }
  }

  return {
    action,
    processing,
  }
}

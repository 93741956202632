import { isBatchValidationErrors } from '../type-guards/isBatchValidationError'
import type { FieldWithValidation } from '~/composables/useFields/types'

export const applyBatchValidationErrors = (
  error: unknown,
  sections?: MaybeRef<Record<string, FieldWithValidation<unknown>>[]>,
  onValidationError?: (index: number, field: string) => unknown,
): boolean => {
  if (sections && isBatchValidationErrors(error)) {
    const handledByField = error.body.batchErrors.reduce<boolean>(
      (result, data) => {
        const fields = unref(sections)[data.index]

        if (!fields) return result

        return Object.entries(data.errors).reduce<boolean>(
          (innerResult, [key, messages]) => {
            const field = fields[key]

            if (!field) return innerResult

            field.data.validationError = messages.map(
              (message) => `apiErrors.${message}`,
            )

            if (!result && typeof onValidationError === 'function')
              onValidationError(data.index, field.data.key)

            return true
          },
          false,
        )
      },
      false,
    )

    return handledByField
  }

  return false
}

import { ApiError } from '~/services/sdk/api'

export const isValidationError = <T extends string>(
  error: unknown,
): error is Omit<ApiError, 'body'> & {
  body: { errors: Record<T, string[]> }
} => {
  return (
    error instanceof ApiError &&
    isTrueObject<{ errors: T }>(error.body) &&
    isTrueObject<T>(error.body.errors)
  )
}
